import { $apollo, $persistor } from '@boot/apollo';
import { i18n } from '@boot/i18n';
import { query, mutation } from '@graphql';
import sz_lang from '@helpers/translate';
import {
  Icommon,
  IconfigWebchat,
  Ilanguage,
  IPagination
} from '@models/general';
import { IdataSignin } from '@models/model';
import { Notify } from 'quasar';
import { getPermissionCache, getUserCache, setUserCache } from './cache';
import { szRoutes } from './SzRoutes';
import { computed } from '@vue/composition-api';
import Vue from 'vue'
import VueCompositionAPI, { inject } from '@vue/composition-api';

Vue.use(VueCompositionAPI)

const { translate } = sz_lang();

type positionType = 'top-right' | 'top-left' | 'bottom-left' | 'bottom-right' | 'top' | 'bottom' | 'left' | 'right' | 'center';

interface notifyOptionsInterface {
  color: string,
  classes: string,
  icon: string,
  position?: positionType,
  timeout: number,
}

export const useUnleash = () => {
  return inject('unleash') as Icommon;
}

export const useFeatureFlags = () => {
  const unleash = useUnleash();

  const isFeatureEnabled = (featureName: string) => unleash.isEnabled(featureName);

  return {
    isFeatureEnabled
  };
}

export const loadView = (view: string) => {
  return async () =>
    await import(
      /* webpackChunkName: "view-[request]" */
      `../stories/pages/${view}`
    );
};

export const loadLayout = (view: string) => {
  return async () =>
    await import(
      /* webpackChunkName: "layout-[request]" */
      `../stories/layout/${view}`
    );
};

export const setTitleWhitalbel = (page: string) => {
  const whitelabel = getStorage('sz_whitelabel');
  const system: string = whitelabel.system_product.toString();

  if(system) {
    return {
      title: `${system} - ${page}`
    };
  }

  return {
    title: page
  }
};

export const getTitleWhitelabel = () => {
  const whitelabel = getStorage('sz_whitelabel');
  const system: string = whitelabel.system_product.toString();
  return {
    title: system
  };
};

export const getMedia = (storage_id: string) => {
  return `/config/storage/view/${storage_id}`;
};

export const getIconPlatform = (
  listPlatforms: Icommon[],
  platform: string
): string => {
  const result = listPlatforms.find(
    (item: Icommon) => item.platform === platform
  );

  if (result?.icon) {
    return result.icon;
  }

  return '';
};

export const getFaviconWhitelabel = () => {
  const whitelabel = getStorage('sz_whitelabel');

  return {
    link: {
      icon: {
        rel: 'icon',
        type: 'image/ico',
        href: whitelabel.favicon
      },
      icon_size_16: {
        rel: 'icon',
        type: 'image/ico',
        sizes: '16x16',
        href: whitelabel.favicon
      },
      icon_size_32: {
        rel: 'icon',
        type: 'image/ico',
        sizes: '32x32',
        href: whitelabel.favicon
      },
      icon_size_96: {
        rel: 'icon',
        type: 'image/ico',
        sizes: '96x96',
        href: whitelabel.favicon
      }
    }
  };
};

export const changeLanguage = (lang: string) => {
  i18n.locale = lang;
  setStorageLang(lang);
};

export const setStorageLang = (lang: string) => {
  localStorage.setItem('sz_language', lang);
};

export const setStorageWhitelabel = (whitelabel: Icommon) => {
  if (whitelabel?.host_ws) {
    const urlPieces = window.location.origin.split('.');
    urlPieces.splice(0, 1);
    const domain = urlPieces.join('.');
    whitelabel.host_ws = `${whitelabel.host_ws}.${domain}`;
  }
  setStorage('sz_whitelabel', whitelabel);
};

export const isEmail = (email: string): boolean => {
  if (!email) {
    return false;
  }
  const re = /^([\w\.\-]+)@([\w\-]+)((\.(\w){1,})+)$/gm;
  return !!re.test(email);
};

export const decodeHtmlEntities = (message: string): string => {
  return message
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&nbsp;/g, ' ')
};

export const parseToWhatsapp = (message: string): string => {
  return message
    .replace(/(<div><br><\/div>|<div><br>)+/g, '\n')
    .replace(/(<\/div>)/g, '')
    .replace(/<div>(\s*[\/]?)<div>/g, '')
    .replace(/<div>/g, '\n')
    .replace(/<b>|<\/b>/g, '*')
    .replace(/<i>|<\/i>/g, '_')
    .replace(/<s>|<\/s>/g, '~')
    .replace(/<div><br\s*[\/]?><\/div>|<br\s*[\/]?>/gi, '\n')
    .replace(/<\/div>$/g, '')
    .replace(/<\/div>/g, '\n')
    .replace(/<div\s*[\/]?>/gi, '')
    .replace(/<[^>]*>/g, '');
};

export const parseToHtml = (message: string): string => {
  return message
    .replace(/\*(.*?)\*/g, '<b>$1</b>')
    .replace(/\_(.*?)\_/g, '<i>$1</i>')
    .replace(/\~(.*?)\~/g, '<s>$1</s>')
    .replace(/(?:\r\n|\r|\n)/g, '<br>');
};

export const removeEmptyAttributes = (obj: Icommon): Icommon => {
  const newObj: Icommon = {};

  Object.keys(obj).forEach(key => {
    if (obj[key] || obj[key] === false) {
      newObj[key] = obj[key];
    }
  });

  return newObj;
};

export const fontLimunisity = (hex: string) => {
  let r = 0;
  let g = 0;
  let b = 0;
  let size = false;
  hex = hex.replace('#', '');
  size = hex.length > 3;

  r = size
    ? parseInt(hex.substr(0, 2), 16)
    : parseInt(hex.substr(0, 1), 16) * 17;
  g = size
    ? parseInt(hex.substr(2, 2), 16)
    : parseInt(hex.substr(1, 1), 16) * 17;
  b = size
    ? parseInt(hex.substr(4, 2), 16)
    : parseInt(hex.substr(2, 1), 16) * 17;

  return (r * 299 + g * 587 + b * 114) / 1000;
};

export const deepClone = (obj, hash = new WeakMap()) => {
  if (Object(obj) !== obj) return obj; // primitives
  if (hash.has(obj)) return hash.get(obj); // cyclic reference
  const result =
    obj instanceof Set
      ? new Set(obj) // See note about this!
      : obj instanceof Map
      ? new Map(Array.from(obj, ([key, val]) => [key, deepClone(val, hash)]))
      : obj instanceof Date
      ? new Date(obj)
      : obj instanceof RegExp
      ? new RegExp(obj.source, obj.flags)
      : // ... add here any specific treatment for other classes ...
      // and finally a catch-all:
      obj.constructor
      ? new obj.constructor()
      : Object.create(null);
  hash.set(obj, result);
  return Object.assign(
    result,
    ...Object.keys(obj).map(key => ({ [key]: deepClone(obj[key], hash) }))
  );
};

export const getParams = (): Icommon => {
  const value = {};
  if (location.href.split('?')[1]) {
    location.href
      .split('?')[1]
      .split('&')
      .forEach(i => {
        value[i.split('=')[0]] = i.split('=')[1];
      });
  }

  return value;
};

export const isURL = (url: string): boolean => {
  if (!url) {
    return false;
  }
  const re = /(https?:\/\/(?:www[0-9]\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  return !!re.test(url);
};

export const isURLWithPlaceholer = (url: string): boolean => {
  const hasPlaceholer = /{{\d+}}/.test(url)
  const hasPlaceholderInTheEnd =  /{{\d+}}$/.test(url)
  const hasManyPlaceholder = url.match(/{{\d+}}/g) || []

  if (hasPlaceholer && hasManyPlaceholder.length > 1) {
    return false;
  }

  if (hasPlaceholer && hasPlaceholderInTheEnd) {
    return true;
  }

  if (hasPlaceholer && !hasPlaceholderInTheEnd) {
    return false;
  }

  return true;
};

export const removeStorage = (name: string): void => {
  localStorage.removeItem(name);
};

export const setStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getStorage = (name: string): any => {
  const storage = localStorage.getItem(name);

  if (storage) {
    return JSON.parse(storage);
  }

  return null;
};

export const getUserLoggedIn = () => {
  const user = getUserCache();

  if (user && user.token) {
    return user;
  }

  return {};
};

export const removeSessionUser = () => {
  $persistor.purge().catch(() => {
    notification('error');
  });
  localStorage.removeItem('user_email');
  window.location.href = szRoutes.login.logout;
};

export const getToken = () => {
  const user: IdataSignin = getUserLoggedIn();

  if (user && user.token) {
    return `Bearer ${user.token.toString()}`;
  }

  return '';
};

export const getClientId = (): string => {
  const user: Icommon = getUserLoggedIn();
  if (user?.client_id) {
    return user.client_id;
  }
  return '';
};

export const getClientLanguage = () => {
  return localStorage.getItem('sz_language');
};

export const languageList = (): Array<Ilanguage> => {
  return [
    { label: translate.value.lang.pt, value: 'pt-BR', flag: 'br' },
    { label: translate.value.lang.es, value: 'es', flag: 'es' },
    { label: translate.value.lang.en, value: 'en', flag: 'us' }
  ];
};

export const languageHsm = () => {
  const list = [
    { label: 'Albanês', value: 'sq', flag: 'al', metaFlag: 'sq_AL'},
    { label: 'Búlgaro', value: 'bg', flag: 'bg', metaFlag: 'bg_BG'},
    { label: 'Chinês ', value: 'zh_CN', flag: 'cn', metaFlag: 'zh_CN' },
    { label: translate.value.lang.pt, value: 'pt_BR', flag: 'br', metaFlag: 'pt_BR' },
    { label: translate.value.lang.es, value: 'es', flag: 'es', metaFlag: 'es_XX' },
    { label: translate.value.lang.en, value: 'en', flag: 'us', metaFlag: 'en_XX' },
    { label: 'francês', value: 'fr', flag: 'fr', metaFlag: 'fr_XX' },
    { label: 'Alemão', value: 'de', flag: 'de', metaFlag: 'de_DE' },
    { label: 'Grego', value: 'el', flag: 'gr', metaFlag: 'el_GR' },
    { label: 'Italiano', value: 'it', flag: 'it', metaFlag: 'it_IT' },
    { label: 'Japones', value: 'ja', flag: 'jp',metaFlag: 'ja_XX' },
    { label: 'Sueco', value: 'sv', flag: 'se', metaFlag: 'sv_SE' },
    { label: 'Turco', value: 'tr', flag: 'tr', metaFlag: 'tr_TR'  },
    { label: 'Dinamarquês', value: 'da', flag: 'dk', metaFlag: 'da_DK' },
    { label: 'Coreano', value: 'ko', flag: 'kr', metaFlag: 'ko_KR' },
    { label: 'Russo', value: 'ru', flag: 'ru', metaFlag: 'ru_RU' },
    { label: 'Sérvio', value: 'sr', flag: 'rs', metaFlag: 'sr_RS' },
  ];

  return list.sort((a, b) => {
    const checkA = a.label ? -1 : 1;
    const checkB = b.label ? -1 : 1;
    return checkA - checkB;
  });
};

export const setSessionSaml = (token: string) => {
  $apollo
    .mutate({
      mutation: mutation.LOGIN_SAML_TOKEN,
      variables: { token: token }
    })
    .then(({ data }) => {
      const oauthSamlToken = data.oauthSamlToken;
      if (oauthSamlToken.token) {
        setUserCache(oauthSamlToken);
        window.location.href = oauthSamlToken.intend;
      } else {
        removeSessionUser();
      }
    })
    .catch(() => {
      removeSessionUser();
    });
};

export const setSessionSupportRemote = (token: string) => {
  $apollo
    .mutate({
      mutation: mutation.LOGIN_TOKEN_SUPPORT_REMOTO,
      variables: { token: token }
    })
    .then(({ data: { oauthTmpToken } }) => {
      if (oauthTmpToken.token) {
        setUserCache(oauthTmpToken);
        window.location.href = oauthTmpToken.intend;
      } else {
        removeSessionUser();
      }
    })
    .catch(() => {
      removeSessionUser();
    });
};

export const supportRemotePermissionRequest = () => {
  const { supportRemote, accessData } = getPermissionCache();
  if (supportRemote) {
    if (accessData) {
      return false;
    }
    // bloqueia request
    return true;
  }

  return false;
};

export const removeNotification = (callback: () => any) => {
  Notify.create({
    message: translate.value.messages.are_you_sure,
    caption: translate.value.messages.you_really_delete,
    color: 'white',
    classes: 'sz_notify',
    icon: 'fas fa-question-circle',
    position: 'center',
    timeout: 0,
    actions: [
      {
        label: translate.value.no,
        color: 'red'
      },
      {
        label: translate.value.yes,
        color: 'green',
        handler: () => {
          callback();
        }
      }
    ]
  });
};
export const resendPendingNotification = async (template, switchableCallbacks: () => any) => {
  const { callbackWithBroker, callbackWithoutBroker } = switchableCallbacks()
  const numberUnsychronized = template?.devices?.filter(device => device.hub_status === 'unsynchronized').map(device => device.number)
  const { countExistentChannelWithBroker } =  await $apollo.query({
    query: query.CHECK_HSM_BROKER,
    variables: {
      numbers: numberUnsychronized
    }
  }).then(({data}) => data)
  .catch((error: any) => {
    const message = error.response?.data?.errors?.message || false;
    if (message) {
      console.error(message)
    } else {
      console.error(error);
    }
  })

  const notifyOptions: notifyOptionsInterface = {
    color: 'white',
    classes: 'sz_notify',
    icon: 'fas fa-question-circle',
    position: 'center',
    timeout: 0,
  }
  const resendOptions = {
    message: translate.value.messages.are_you_sure,
    caption: translate.value.messages.really_want_to_resend,
    actions: [
      {
        label: translate.value.no,
        color: 'red',
      },
      {
        label: translate.value.yes,
        color: 'green',
        handler: () => {
          callbackWithBroker();
        },
      },
    ],
  }
  const existentChannelsWithBroker = countExistentChannelWithBroker === numberUnsychronized.length
  if(!existentChannelsWithBroker) {
    resendOptions.message = translate.value.attention
    resendOptions.caption = translate.value.messages.redirect_to_update
    resendOptions.actions[1].handler = () => {
      callbackWithoutBroker(template)
    }
  }

  Object.assign(notifyOptions, resendOptions)
  Notify.create(notifyOptions);
};

export const prepareGraphQLObject = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(item => prepareGraphQLObject(item));
  }

  const newObj = {};
  for (const key in obj) {
    if (key !== '__typename') {
      newObj[key] = prepareGraphQLObject(obj[key]);
    }
  }

  return newObj;
}


export const notification = (type: string, options: Icommon = {}) => {
  let config = {};
  switch (type) {
    case 'success':
      config = {
        message: options.message || translate.value.messages.success,
        caption: options.caption || '',
        progress: options.progress || true,
        icon: options.icon || 'fas fa-check-circle',
        type: 'positive',
        position: options.position || 'top-right'
      };
      break;
    case 'error':
      config = {
        message: 'OPS!',
        caption: options.message || translate.value.messages.error,
        progress: options.progress || true,
        icon: options.icon || 'fas fa-times-circle',
        type: 'negative',
        position: options.position || 'top-right'
      };
      break;
    case 'warning':
      config = {
        message: options.message || '',
        caption: options.caption || '',
        progress: options.progress || true,
        icon: options.icon || 'fas fa-exclamation-triangle',
        type: 'warning',
        position: options.position || 'top-right'
      };
      break;
    default:
      config = {
        message: options.message || '',
        caption: options.caption || '',
        progress: options.progress || true,
        icon: options.icon || 'fas fa-info-circle',
        type: 'info',
        position: options.position || 'top-right'
      };
      break;
  }
  Notify.create(config);
};

export const confirmNotification = (
  callback: () => any,
  options: Icommon = {
    message: translate.value.messages.are_you_sure,
    caption: '',
    icon: '',
    textConfirm: translate.value.yes,
    textCancel: translate.value.no
  }
) => {
  Notify.create({
    message: options.message || translate.value.messages.are_you_sure,
    caption: options.caption || '',
    color: 'white',
    classes: 'sz_notify',
    icon: options.icon || 'fas fa-question-circle',
    position: 'center',
    timeout: 0,
    multiLine: true,
    actions: [
      {
        label: options.textCancel || translate.value.no,
        color: 'red'
      },
      {
        label: options.textConfirm || translate.value.yes,
        color: 'green',
        handler: () => {
          callback();
        }
      }
    ]
  });
};

export const alertNotification = (
  callback: () => any,
  options: Icommon = {
    message: translate.value.messages.are_you_sure,
    caption: '',
    icon: '',
    textConfirm: translate.value.yes
  }
) => {
  Notify.create({
    message: options.message || translate.value.messages.are_you_sure,
    caption: options.caption || '',
    color: 'white',
    classes: 'sz_notify',
    icon: options.icon || 'fas fa-question-circle',
    position: 'center',
    timeout: 0,
    multiLine: true,
    actions: [
      {
        label: options.textConfirm || translate.value.yes,
        color: 'blue',
        handler: () => {
          callback();
        }
      }
    ]
  });
};

export const clipboard = (text: string) => {
  navigator.clipboard.writeText(text).catch(() => {
    notification('error');
  });
  notification('info', { message: translate.value.messages.copy });
};

export const generatePaginate = (value?: Icommon): IPagination => {
  return {
    currentPage: value?.currentPage || 0,
    itemCount: value?.itemCount || 0,
    itemsPerPage: value?.itemsPerPage || 0,
    rowsPerPage: value?.itemsPerPage || 10,
    totalPages: value?.totalPages || 0,
    totalItems: value?.totalItems || 0
  };
};

export function requestPagination(query: any, params: any): Promise<any> {
  return $apollo.query({
    query,
    variables: { ...params }
  });
}

export const codeWebChat = ({ id, version }: IconfigWebchat) => {
  const uri = window.origin;

  if (version === 'V2') {
    return {
      code: `<script type="text/javascript">
          (function(s, z, c, h, a, t) {
            s.webchat = s.webchat || function() {
              (s.webchat.q = s.webchat.q || []).push(arguments);
            };
            t = z.createElement(c),
              a = z.getElementsByTagName(c)[0]; t.async = 1;
            t.src = '${uri}/webchat/v2/webchat.js'; 
            a.parentNode.insertBefore(t, a);
            s.webchat('cid', '${id}');
            s.webchat('host', h);
          })(window, document, 'script', '${uri}');

          window.addEventListener("message", function(event) {
            if(event.origin !== '${uri}') return;
          },false);
        </script>`,
      link: `${uri}/webchat/v2/?cid=${id}&host=${uri}`
    };
  }
  return {
    code: `<script>
     var __sz4 = {'host':'${uri}','hash':'${id}'};
    <script>

    <script src='${uri}/webchat.app/js/app.js'></script>
    `,
    link: `${uri}/webchat/form/${id}`
  };
};

export const parseStringForJson = (string: string) => {
  try {
    return JSON.parse(string);
  } catch {
    return string;
  }
};

export const openPermissionDialog360 = (number = '', partnerId) => {
  return new Promise(res => {
    let urlPermissions = `https://hub.360dialog.com/dashboard/app/${partnerId}/permissions?hosting_type=onpremise`;

    if (number) {
      urlPermissions = `${urlPermissions}&number=${number}`;
    }

    const windowD360: any = window.open(
      urlPermissions,
      'Permissions at Number',
      'height=900, width=600'
    );
    const checkWindowClosed: any = setInterval(() => {
      windowD360.postMessage({ returnKey: true }, '*');
    }, 1000);

    if (windowD360.focus) {
      windowD360.focus();
    }

    window.addEventListener(
      'message',
      event => {
        if (event.data.message) {
          res(event.data);
          windowD360.close();
          clearInterval(checkWindowClosed);
        }
      },
      false
    );
  });
};

export const openOauthAuthentication = ({}, options) => {
  return new Promise(res => {
    const windowMS: any = window.open(
      options.path,
      'Permissions oauth',
      'location=0, status=0, toolbar=no, menubar=no, width=600, height=700, top=100, left=100'
    );
    const checkWindowClosed: any = setInterval(() => {
      windowMS.postMessage({ returnOauth: true }, '*');
    }, 1000);

    if (windowMS.focus) {
      windowMS.focus();
    }

    window.addEventListener(
      'message',
      event => {
        if (event.data.message) {
          res(event.data);
          windowMS.close();
          clearInterval(checkWindowClosed);
        }
      },
      false
    );
  });
};

export function renderPhotoAdmin(agentId: string, photo: string | null) {
  if (!!photo && !!agentId) {
    return `${window.location.origin}/photos/agent/${agentId}?sz=${
      photo
    }`;
  }
  return '';
}

export function getLogo() {
  const logo = computed(() => {
    const { brand } = getStorage('sz_whitelabel');

    if (brand) {
      return brand;
    }

    return '';
  });

  return { logo }
}